import { useState } from 'react'
import ReactPlayer from 'react-player'

function App () {
  const [url, setUrl] = useState('http://superapi.pl/livestream/test123.flv')

  const handleChange = e => {
    setUrl(e.target.value)
  }

  return (
    <div className='bg-slate-900 flex items-center h-full w-full flex-col gap-8'>
      <div className='flex flex-col justify-center items-center gap-8 container mt-10'>
        <h1 className='text-center text-white text-4xl bg-slate-700 rounded-lg p-4'>
          Hello on my stream!
        </h1>
        <ReactPlayer url={url} controls width='1200px' height='720px' />
        <input
          className='p-4 w-[640px] m-8'
          placeholder='https://www.youtube.com/watch?v=vOSpZwftRhg'
          type='text'
          onChange={handleChange}
          muted
        />
      </div>
    </div>
  )
}

export default App
